var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "pa-2 d-flex"
  }, [_c('v-btn', {
    attrs: {
      "width": "16em"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'SelectSession'
        });
      }
    }
  }, [_vm._v(" Go back to sessions list ")]), _c('v-btn', {
    staticClass: "ml-2",
    on: {
      "click": function ($event) {
        return _vm.$refs.dialogRef.addSubject();
      }
    }
  }, [_vm._v(" New Subject ")]), _c('v-checkbox', {
    staticClass: "ml-2 mt-0",
    attrs: {
      "label": "Show removed subjects"
    },
    model: {
      value: _vm.show_trashed,
      callback: function ($$v) {
        _vm.show_trashed = $$v;
      },
      expression: "show_trashed"
    }
  })], 1)]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-data-table', {
    staticClass: "subjects-table mx-2 mb-4 flex-grow-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.valid_subjects,
      "options": _vm.options,
      "item-class": _vm.itemClasses,
      "loading": _vm.loading,
      "server-items-length": _vm.subject_total,
      "footer-props": {
        showFirstLastPage: false,
        disableItemsPerPage: true,
        itemsPerPageOptions: [40]
      },
      "height": "80vh",
      "fixed-header": "",
      "single-select": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      },
      "item-selected": _vm.onSelect,
      "click:row": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "float-right"
        }, [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
            }
          }], null, true),
          model: {
            value: item.isMenuOpen,
            callback: function ($$v) {
              _vm.$set(item, "isMenuOpen", $$v);
            },
            expression: "item.isMenuOpen"
          }
        }, [_c('v-list', [!item.trashed ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;

              _vm.editSubject(item);
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Edit")])], 1) : _vm._e(), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSubjectHideMenu,
            expression: "clickOutsideDialogSubjectHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [!item.trashed ? _c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Trash")])], 1) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.remove_dialog,
            callback: function ($$v) {
              _vm.remove_dialog = $$v;
            },
            expression: "remove_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "red"
          }
        }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to trash subject "), _c('code', [_vm._v(_vm._s(item.name))]), _vm._v("? You will be able to restore it for 30 days. After that, this subject will be permanently removed. ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "red darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_dialog = false;

              _vm.trashSubject(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSubjectHideMenu,
            expression: "clickOutsideDialogSubjectHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [item.trashed ? _c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Restore")])], 1) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.restore_dialog,
            callback: function ($$v) {
              _vm.restore_dialog = $$v;
            },
            expression: "restore_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "green"
          }
        }, [_vm._v("mdi-undo-variant")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to restore subject "), _c('code', [_vm._v(_vm._s(item.name))]), _vm._v("? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_dialog = false;

              _vm.restoreSubject(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSubjectHideMenu,
            expression: "clickOutsideDialogSubjectHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var on = _ref5.on;
              return [item.trashed ? _c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Delete permanently")])], 1) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.remove_permanently_dialog,
            callback: function ($$v) {
              _vm.remove_permanently_dialog = $$v;
            },
            expression: "remove_permanently_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "red"
          }
        }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to "), _c('strong', [_vm._v("permanently")]), _vm._v(" remove subject "), _c('code', [_vm._v(_vm._s(item.name))]), _vm._v("? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_permanently_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "red darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_permanently_dialog = false;

              _vm.permanentRemoveSubject(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSubjectHideMenu,
            expression: "clickOutsideDialogSubjectHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [!item.trashed & _vm.isSyncDownloadAllowed ? _c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, on), [_c('v-list-item-title', [_vm._v("Download data (old)")])], 1) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.download_dialog,
            callback: function ($$v) {
              _vm.download_dialog = $$v;
            },
            expression: "download_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "green"
          }
        }, [_vm._v("mdi-download")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to download all data associated to the subject "), _c('code', [_vm._v(_vm._s(item.name))]), _vm._v("? (This includes every session and trial associated to it, and can take several minutes). ")])])], 1)], 1), _c('v-card-actions', {
          staticClass: "d-flex justify-center"
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.download_dialog = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": "",
            "disabled": _vm.downloading
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.download_dialog = false;

              _vm.downloadSubjectData(item.id);
            }
          }
        }, [_vm._v(" Download ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "cursor-pointer mt-2"
        }, [_vm._v(_vm._s(item.name))])];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.selected ? _c('v-data-table', {
    staticClass: "mx-2",
    attrs: {
      "headers": _vm.sessionHeaders,
      "items": _vm.valid_sessions,
      "options": _vm.session_options,
      "item-class": _vm.itemClasses,
      "loading": _vm.session_loading,
      "server-items-length": _vm.session_total,
      "footer-props": {
        showFirstLastPage: false,
        disableItemsPerPage: true,
        itemsPerPageOptions: [40]
      },
      "fixed-header": "",
      "height": "80vh",
      "single-select": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.session_options = $event;
      },
      "click:row": _vm.onRowSessionClick
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Session ID ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Session Name ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Trials ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Date ")])])];
      },
      proxy: true
    }], null, false, 1745656332)
  }) : _vm._e()], 1)], 1), _c('DialogComponent', {
    ref: "dialogRef",
    on: {
      "subject-updated": _vm.submitEditSubject,
      "subject-added": _vm.loadValidSubjects
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }